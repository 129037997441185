import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { getHeaders } from '../../../request';
import Imagens from '../../laudo/imagens';
import { SITUACAO_LAUDO } from '../constantes';
import { temPermissaoRBAC, temPermissaoRede } from '../../../secutity/acl';
import Priorizar from '../priorizar';
import Invalidar from '../invalidar';
import { PERMISSOES, VERSAO } from '../../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import alerta from '../../../signals/alerta';
import usuario from '../../../signals/usuario';
import LesoesV1 from './lesoes-v1';
import LesoesV2 from './lesoes-v2';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttGrid,
    SttNotification, 
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    protocolo: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    fonteProtocolo: {
        fontFamily: '"Noto Sans"'
    },
    fileCopyIcon: {
        fontSize: '1.2em',
        cursor: 'pointer',
        color: '#0A6FB8'
    }
}));

const Visualizacao = (props) => {
    const { imprimirFn, imprimirProtocoloFn, imprimirTermoFn } = props;
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

    const idExame = useSignal(null);
    const exame = useSignal(null);
    const priorizar = useSignal(false);
    const invalidar = useSignal(false);
    const permissaoBtns = useSignal([]);
    const textoTooltip = useSignal(strings.copiar);

    useSignals();
    
    useSignalEffect(() => {
        if (id) {
            navigate('/exames', { replace: true });
            navigate('/exames/visualizar', { state: { exame: Buffer.from(id, 'base64').toString('utf-8') } });
        }

        if (usuario.value.perfisRBAC && exame.value) {
            let rede;
            usuario.value.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === exame.value.exame.id_rede_telemedicina) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                permissaoBtns.value = [
                    temPermissaoRede(rede[0], PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede[0], PERMISSOES.PRIORIZAR_EXAME)
                ];
            }
        }
        if (location.state?.exame) {
            idExame.value = location.state.exame;
        }
    });

    useSignalEffect(() => {
        axios
            .get(`${ESTOMATO_API_BASE_URL}/exame/${idExame}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                exame.value = data;
            })
            .catch(err => {
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let arrMensagem = [];
                if (response) {
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }

                alerta.value = {
                    title: strings.erro,
                    message: msg,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = { ...alerta.value, open: false };
                                voltar();
                            }

                        }
                    ],
                    onClose: () => {
                        alerta.value = { ...alerta.value, open: false };
                        voltar();
                    }
                };
            });
    });

    const voltar = () => {
        navigate('/exames', { state: { from: 'visualizar' } });
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !exame.value
                ?
                <div className={classes.carregando}>
                    <SttCircularProgress color="primary" />
                </div>
                :
                <>
                    <SttHeading variant="h1" color="primary" align="center">{exame.value.exame.descricao_exame}</SttHeading>

                    <SttExpansionPanel
                        title={strings.paciente}
                        children={
                            <div>
                                <SttTextItem title={strings.nome} content={exame.value.exame.nome_paciente} />
                                <SttTextItem title={strings.dataNascimento} content={exame.value.exame.data_nascimento_paciente_formatada} />
                                <SttTextItem title={strings.sexo} content={exame.value.exame.sexo_paciente} />
                                { exame.value.indicacao?.peso_paciente && <SttTextItem title={strings.peso} content={`${exame.value.indicacao.peso_paciente} kg`} /> }
                                { exame.value.indicacao?.altura_paciente && <SttTextItem title={strings.altura} content={`${exame.value.indicacao.altura_paciente} cm`} />}
                                <SttTextItem title={strings.idade} content={`${exame.value.exame.idade_paciente} anos`} />
                                { exame.value.exame.cartao_sus_paciente && <SttTextItem title={strings.cns} content={exame.value.exame.cartao_sus_paciente} /> }
                            </div>
                        }
                    />

                    <SttExpansionPanel
                        title={strings.dadosExame}
                        children={
                            <div>
                                <SttTextItem title={strings.modalidade} content={exame.value.exame.descricao_modalidade} />
                                <SttTextItem title={strings.dataExame} content={`${exame.value.exame.data_exame_formatada} ${global.gConfig.sufixo_data_hora}`} />
                                <div className={classes.protocolo}>
                                    <SttTextItem classContent={classes.fonteProtocolo} title={strings.protocolo} content={exame.value.exame.protocolo_rctm} />
                                    <Tooltip 
                                        title={textoTooltip.value} 
                                        arrow
                                        onClick={() => {
                                            navigator.clipboard.writeText(exame.value.exame.protocolo_rctm);
                                            textoTooltip.value = strings.protocoloCopiado;
                                        }} 
                                        onClose={(e) => textoTooltip.value = strings.copiar}
                                    >
                                        <FileCopyIcon className={classes.fileCopyIcon} />
                                    </Tooltip>

                                </div>
                                <SttTextItem title={strings.requisicao} content={exame.value.exame.requisicao} />
                                <SttTextItem title={strings.instituicao} content={exame.value.exame.nome_instituicao} />
                                <SttTextItem title={strings.medicoSolicitante} content={exame.value.exame.nome_medico_solicitante} />
                                { exame.value.exame.nome_medico_executor && <SttTextItem title={strings.medicoExecutor} content={exame.value.exame.nome_medico_executor} /> }
                            </div>
                        }
                    />

                    {
                        (exame.value.indicacao || exame.value.exame.observacao_tecnica) &&
                        <SttExpansionPanel
                            title={strings.outrasInformacoes}
                            children={
                                <div>
                                    {exame.value.exame.observacao_tecnica && <SttTextItem title={strings.observacaoTecnica} content={exame.value.exame.observacao_tecnica} /> }
                                    {exame.value.indicacao?.usuario_protese && <SttTextItem title={strings.pacienteUsuarioProtese} content={exame.value.indicacao.usuario_protese} />}
                                    {exame.value.indicacao?.protese && <SttTextItem title={strings.situacaoProtese} content={exame.value.indicacao.situacao_protese} />}
                                    {exame.value.indicacao?.protese && <SttTextItem title={strings.abrangenciaProtese} content={exame.value.indicacao.abrangencia_protese} />}
                                    {exame.value.indicacao?.tratamento_previo && <SttTextItem title={strings.tratamentoPrevio} content={exame.value.indicacao.tratamento_previo} />}
                                    {exame.value.indicacao?.possibilidade_trauma_local && <SttTextItem title={strings.possibilidadeTraumaLocal} content={exame.value.indicacao.possibilidade_trauma_local} />}
                                </div>
                            }
                        />
                    }

                    {
                        (exame.value.indicacao?.fumante || exame.value.indicacao?.etilista || exame.value.indicacao?.usuario_drogas) &&
                        <SttExpansionPanel
                            title={strings.habitos}
                            children={
                                <div>
                                    {exame.value.indicacao?.fumante && <SttTextItem title={strings.fumante} content={exame.value.indicacao.fumante} />}
                                    {exame.value.indicacao?.tempo_tabagismo && <SttTextItem title={strings.tempoTabagismo} content={exame.value.indicacao.tempo_tabagismo} />}
                                    {exame.value.indicacao?.tempo_ex_tabagismo && <SttTextItem title={strings.tempoExTabagista} content={exame.value.indicacao.tempo_ex_tabagismo} />}
                                    {exame.value.indicacao?.tipo_fumo && <SttTextItem title={strings.tipoFumo} content={exame.value.indicacao.tipo_fumo} />}
                                    
                                    {exame.value.indicacao?.etilista && <SttTextItem title={strings.etilista} content={exame.value.indicacao.etilista} />}
                                    {exame.value.indicacao?.tempo_etilismo && <SttTextItem title={strings.tempoEtilismo} content={exame.value.indicacao.tempo_etilismo} />}
                                    {exame.value.indicacao?.tempo_ex_etilista && <SttTextItem title={strings.tempoExEtilista} content={exame.value.indicacao.tempo_ex_etilista} />}
                                    {exame.value.indicacao?.tipo_bebida && <SttTextItem title={strings.tipoBebida} content={exame.value.indicacao.tipo_bebida} />}
                                    
                                    {exame.value.indicacao?.usuario_drogas && <SttTextItem title={strings.usuarioDrogas} content={exame.value.indicacao.usuario_drogas} />}
                                    {exame.value.indicacao?.tempo_usuario_drogas && <SttTextItem title={strings.tempoUsuarioDrogas} content={exame.value.indicacao.tempo_usuario_drogas} />}
                                    {exame.value.indicacao?.tempo_ex_usuario_drogas && <SttTextItem title={strings.tempoExUsuarioDrogas} content={exame.value.indicacao.tempo_ex_usuario_drogas} />}
                                    {exame.value.indicacao?.tipo_droga && <SttTextItem title={strings.tipoDroga} content={exame.value.indicacao.tipo_droga} />}

                                    {exame.value.indicacao?.exposicao_sol_horas && <SttTextItem title={strings.exposicaoSolHorasDia} content={exame.value.indicacao.exposicao_sol_horas} />}
                                    {exame.value.indicacao?.exposicao_sol_meses && <SttTextItem title={strings.exposicaoSolMesesAno} content={exame.value.indicacao.exposicao_sol_meses} />}
                                    {exame.value.indicacao?.protecao_solar_tempo && <SttTextItem title={strings.tempoUsoProtecaoSolar} content={exame.value.indicacao.protecao_solar_tempo} />}
                                    {exame.value.indicacao?.protecao_solar_frequencia && <SttTextItem title={strings.frequenciaUsoProtecaoSolar} content={exame.value.indicacao.protecao_solar_frequencia} />}
                                </div>
                            }
                        />
                    }


                    {
                        exame.value.indicacao &&
                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            children={
                                <div>
                                    <SttTextItem title={strings.queixaPrincipal} content={exame.value.indicacao.queixa_principal} />
                                    {
                                        exame.value.exame.versao === VERSAO.ANTIGA ?
                                        <LesoesV1 exame={exame} /> :
                                        <LesoesV2 exame={exame} />
                                    }
                                </div>
                            }
                        />
                    }

                    {
                        !exame.value.exame.valido && 
                        <SttNotification severity="info" className={classes.notification}>
                            <SttAlertTitle>{strings.exameInvalido}</SttAlertTitle>
                            <div className={classes.notificationContent}>
                                <span>{`${strings.motivo}: ${exame.value.exame.motivo_invalidacao}`}</span>
                                <span>{`${strings.responsavel}: ${exame.value.exame.responsavel_invalidacao}`}</span>
                                <span>{`${strings.data}: ${exame.value.exame.data_invalidacao} ${global.gConfig.sufixo_data_hora}`}</span>
                            </div>
                        </SttNotification>
                    }

                    { 
                        temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_LAUDO) && exame.value.laudo && 
                        <SttExpansionPanel
                            title={strings.laudo}
                            children={
                                <div dangerouslySetInnerHTML={{ __html: exame.value.laudo }}></div>
                            }
                        />
                    }
                    
                    {
                        temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_IMAGEM) && 
                        (
                            (
                                exame.value.imagensIncompletas && 
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttNotification severity="info">{strings.imagensIndisponiveis}</SttNotification>
                                    </SttGrid>
                                </SttGrid>

                            ) ||
                            <Imagens idExame={idExame.value} />
                        )
                    }
                    {
                        exame.value.exame.valido && 
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        imprimirFn(idExame.value)
                                    }}
                                    nomarginleft="true"
                                >
                                    {strings.imprimir}
                                </SttButton>
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        imprimirProtocoloFn(exame.value.exame)
                                    }}
                                >
                                    {strings.protocolo}
                                </SttButton>
                                {
                                    (permissaoBtns[1] || 
                                        (permissaoBtns[1] === undefined && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) && 
                                            exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => priorizar.value = true}
                                    >
                                        {strings.priorizar}
                                    </SttButton>
                                }
                                {
                                    (((permissaoBtns[0] || 
                                        (permissaoBtns[0] === undefined && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) && 
                                            exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) || 
                                    (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO) && 
                                    exame.value.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) && 
                                    exame.value.exame.valido &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => invalidar.value = true}
                                    >
                                        {strings.invalidar}
                                    </SttButton>
                                }
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        imprimirTermoFn(exame.value.exame)
                                    }}
                                >
                                    {strings.imprimirTermo}
                                </SttButton>
                            </SttGrid>
                        </SttGrid>
                    }

                    {
                        (permissaoBtns[1] || 
                            (permissaoBtns[1] === undefined && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) && 
                                exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && exame.value.exame.valido &&
                        <Priorizar 
                            priorizar={priorizar.value}
                            setPriorizar={(p) => priorizar.value = p} 
                            idExame={idExame.value} 
                            idInstituicao={exame.value.exame.id_instituicao} 
                            callback={voltar} 
                        />
                    }
                    {
                        (((permissaoBtns[0] || 
                                (permissaoBtns[0] === undefined && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) && 
                                    exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) || 
                        (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO) && 
                        exame.value.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) && 
                        exame.value.exame.valido &&
                        <Invalidar 
                            invalidar={invalidar.value} 
                            setInvalidar={(i) => invalidar.value = i} 
                            idExame={idExame.value}
                            idInstituicao={exame.value.exame.id_instituicao}
                            idModalidade={exame.value.exame.id_modalidade} 
                            callback={voltar} />
                    }
                </>
            }
        </SttContainer>
    )

}

export default Visualizacao;