import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Field, FastField, useFormikContext } from 'formik';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { getHeaders } from '../../request';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import Form from '../solicitacao/lesoes/form';
import {
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttGrid,
    SttButton,
    SttModal,
    SttText,
    SttDivider,
    SttInput,
    SttHeading,
    SttRadioButton, 
    SttRadioGroup, 
    SttFormHelperText,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import { COMPLEMENTO_LESAO_FUNDAMENTAL, COR, LESAO_FUNDAMENTAL, LESOES, LOCALIZACAO, OBSERVACAO_SUPERFICIE, OUTRA_COR, SUPERFICIE } from '../solicitacao/lesoes/fieldNames';
import { CLASSIFICACAO_RISCO, COMPLEMENTO_HIPOTESE_DIAGNOSTICA, CONCLUSAO, HIPOTESE_DIAGNOSTICA, ORIENTACOES } from './fieldNames';

const useStyles = makeStyles(theme => ({
    modalBody: {
        overflow: 'hidden'
    }
}));

const readOnly = { tamanho: true, palpacao: true, tempoEvolucao: true, tipoCrescimento: true, sintomatologia: true };
const required = {};

const LaudoTextualLesao = (props) => {
    const { refs, lesoes, numeroLesao, versao, indice } = props;    
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const { values, setFieldValue } = useFormikContext();
    const indiceLesao = useSignal(indice);
    const copiarLaudo = useSignal(false);
    const outrasLesoes = useSignal([]);
    const outrasLesoesSelecionadas = useSignal([]);
    const listaClassificacaoRisco = useSignal([]);
    const listaHipoteseDiagnostica = useSignal([]);
    
    useSignals();

    useEffect(() => {
        // Separa as outras lesões
        const outras = lesoes.filter(lesao => lesao.numero !== numeroLesao);
        outrasLesoes.value = outras;

        const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;
        
        axios
            .get(`${ESTOMATO_API_BASE_URL}/classificacao-risco`, { headers: getHeaders() })
            .then((response) => {
                listaClassificacaoRisco.value = response.data;
            })
            .catch(err => console.log(err));
        
        axios
            .get(`${ESTOMATO_API_BASE_URL}/hipotese-diagnostica`, { headers: getHeaders() })
            .then((response) => {
                listaHipoteseDiagnostica.value = response.data;
            })
            .catch(err => console.log(err));
    }, [lesoes]);

    const cancelarCopiaLaudo = () => {
        copiarLaudo.value = false;
        outrasLesoesSelecionadas.value = [];
    }

    const handleCheckOutraLesao = (event, value) => {
        let selecionadas;
        let selecionada = parseInt(event.target.value);
        if (value) {
            selecionadas = [
                ...outrasLesoesSelecionadas.value,
                selecionada
            ];
        } else {
            selecionadas = outrasLesoesSelecionadas.value.filter(s => s !== selecionada);
        }
        outrasLesoesSelecionadas.value = selecionadas;
    }

    const confirmarCopiarLaudo = () => {
        
        outrasLesoesSelecionadas.value.forEach(ols => {

            const idx = lesoes.findIndex(l => l.numero === ols);
            const laudoLesaoDestino = {
                ...values[LESOES][idx],
                [LESAO_FUNDAMENTAL]: values[LESOES][indiceLesao.value][LESAO_FUNDAMENTAL],
                [COMPLEMENTO_LESAO_FUNDAMENTAL]: values[LESOES][indiceLesao.value][COMPLEMENTO_LESAO_FUNDAMENTAL],
                [LOCALIZACAO]: values[LESOES][indiceLesao.value][LOCALIZACAO],
                [COR]: values[LESOES][indiceLesao.value][COR],
                [OUTRA_COR]: values[LESOES][indiceLesao.value][OUTRA_COR],
                [SUPERFICIE]: values[LESOES][indiceLesao.value][SUPERFICIE],
                [OBSERVACAO_SUPERFICIE]: values[LESOES][indiceLesao.value][OBSERVACAO_SUPERFICIE],
                [CLASSIFICACAO_RISCO]: values[LESOES][indiceLesao.value][CLASSIFICACAO_RISCO],
                [HIPOTESE_DIAGNOSTICA]: values[LESOES][indiceLesao.value][HIPOTESE_DIAGNOSTICA],
                [COMPLEMENTO_HIPOTESE_DIAGNOSTICA]: values[LESOES][indiceLesao.value][COMPLEMENTO_HIPOTESE_DIAGNOSTICA],
                [ORIENTACOES]: values[LESOES][indiceLesao.value][ORIENTACOES],
                [CONCLUSAO]: values[LESOES][indiceLesao.value][CONCLUSAO],
            }
            setFieldValue(`${LESOES}[${idx}]`, laudoLesaoDestino);
        });
        NotificationManager.success(strings.dadosCopiados);
        cancelarCopiaLaudo();
    }

    return (
        values[LESOES][indiceLesao.value] &&
        listaClassificacaoRisco.value.length > 0 && 
        listaHipoteseDiagnostica.value.length > 0 &&
        <SttGrid container spacing={3}>
            <SttGrid item xs={12}>
                <Form 
                    indiceLesao={indiceLesao} 
                    refs={refs} 
                    readOnly={readOnly}
                    required={required}
                    versao={versao}
                />
            </SttGrid>
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${CLASSIFICACAO_RISCO}`}>
                    {({
                        field,
                        meta, 
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary" required>{strings.classificacaoRisco}</SttHeading>
                            <div ref={refs.classificacaoRiscoRef}></div>
                            <SttRadioGroup row>
                                {
                                    listaClassificacaoRisco.value.map((classificacao, idx) => (
                                        <SttFormControlLabel 
                                            key={idx}
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={classificacao.sigla}
                                                    color="primary"
                                                    checked={field.value === classificacao.sigla}
                                                    onClick={(e)=> setFieldValue(`${LESOES}[${indiceLesao.value}].${HIPOTESE_DIAGNOSTICA}`, null)}   
                                                />
                                            }
                                            label={classificacao.descricao}
                                        />
                                    ))
                                }
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${HIPOTESE_DIAGNOSTICA}`}>
                    {({
                        field: { name, value, onBlur },
                        form,
                        meta,
                    }) => (
                        <SttAutocomplete 
                            inputprops={{
                                name: name,
                                label: strings.hipoteseDiagnostica,
                                error: meta.touched && meta.error ? meta.error : undefined,
                                required: true
                            }}
                            filterOptions={(options) => options.filter(lhd => lhd.sigla_laudo_estomato_cadastro_classificacao_risco === values[LESOES][indiceLesao.value][CLASSIFICACAO_RISCO])}
                            getOptionLabel={option => option ? option.descricao : ''}
                            options={listaHipoteseDiagnostica.value}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e, item) => {
                                form.setFieldValue(`${LESOES}[${indiceLesao.value}].${HIPOTESE_DIAGNOSTICA}`, item || null);
                                form.setFieldValue(`${LESOES}[${indiceLesao.value}].${COMPLEMENTO_HIPOTESE_DIAGNOSTICA}`, null);
                            }}
                        />
                    )}
                </Field>
            </SttGrid>
            {
                values[LESOES][indiceLesao.value][HIPOTESE_DIAGNOSTICA]?.exige_complemento &&
                <SttGrid item xs={12}>
                    <Field name={`${LESOES}[${indiceLesao.value}].${COMPLEMENTO_HIPOTESE_DIAGNOSTICA}`}>
                        {({
                            field,
                            meta
                        }) => (
                            <SttInput
                                multiline
                                minRows={3}
                                {...field}
                                required
                                label={strings.complemento}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </Field>
                </SttGrid>
            }
            <SttGrid item xs={12} sm={12}>
                <FastField name={`${LESOES}[${indiceLesao.value}].${ORIENTACOES}`}>
                    {({
                        field,
                        meta
                    }) => (
                        <SttInput
                            multiline
                            minRows={5}
                            {...field}
                            label={strings.orientacoes}
                            error={meta.touched && meta.error ? true : false}
                            helperText={meta.touched && meta.error ? meta.error : undefined}
                        />
                    )}
                </FastField>
            </SttGrid>
            <SttGrid item xs={12} sm={12}>
                <FastField name={`${LESOES}[${indiceLesao.value}].${CONCLUSAO}`}>
                    {({
                        field,
                        meta
                    }) => (
                        <SttInput
                            multiline
                            minRows={5}
                            {...field}
                            label={strings.conclusao}
                            error={meta.touched && meta.error ? true : false}
                            helperText={meta.touched && meta.error ? meta.error : undefined}
                        />
                    )}
                </FastField>
            </SttGrid>
            <SttGrid item xs={12}>
                {
                    outrasLesoes.value.length > 0 && 
                    <SttGrid container spacing={3}>
                        <SttGrid item xs={12}>
                            <SttButton
                                variant="contained"
                                color="primary"
                                nomarginleft="true"
                                onClick={() => copiarLaudo.value = true}
                            >
                                {strings.copiarLaudo}
                            </SttButton>
                        </SttGrid>
                    </SttGrid>
                }
                <SttModal
                    title={strings.copiarLaudo}
                    open={copiarLaudo.value}
                    outModalClose={cancelarCopiaLaudo}
                    iconClose={cancelarCopiaLaudo}
                    maxWidth="sm"
                    fullWidth={true}
                    children={
                        <div className={classes.modalBody}>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttText>{strings.copiarLaudoInfo}</SttText>
                                    <SttDivider />
                                </SttGrid>
                            </SttGrid>


                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttText>{strings.copiarPara}</SttText>
                                    <SttFormControl fullWidth variant="outlined">
                                        <SttFormGroup>
                                            {
                                                outrasLesoes.value.map((lesao) => (
                                                    <SttFormControlLabel
                                                        control={
                                                            <SttCheckbox
                                                                value={lesao.numero}
                                                                color="primary"
                                                                checked={outrasLesoesSelecionadas.value.includes(lesao.numero)}
                                                                onChange={handleCheckOutraLesao}
                                                            />
                                                        }
                                                        key={lesao.numero}
                                                        label={`${strings.lesao} ${lesao.numero} - ${lesao.descricao_lesao}`}
                                                    />
                                                ))
                                            }
                                        </SttFormGroup>
                                    </SttFormControl>
                                </SttGrid>
                            </SttGrid>
                        </div>

                    }
                    footer={
                        <div>
                            <SttButton variant="contained" color="primary" onClick={confirmarCopiarLaudo} disabled={!outrasLesoesSelecionadas.value.length}>
                                {strings.confirmar}
                            </SttButton>
                            <SttButton variant="outlined" color="primary" onClick={cancelarCopiaLaudo}>
                                {strings.cancelar}
                            </SttButton>
                        </div>
                    }
                />
            </SttGrid>
       </SttGrid>
    );
}

LaudoTextualLesao.propTypes = {
    strings: PropTypes.object.isRequired,
    numeroLesao: PropTypes.number.isRequired,
    lesoesElementares: PropTypes.array.isRequired,
    lesoesSecundarias: PropTypes.array.isRequired,
    coloracoes: PropTypes.array.isRequired,
    morfologias: PropTypes.array.isRequired,
    distribuicoes: PropTypes.array.isRequired,
    tamanhos: PropTypes.array.isRequired,
    localizacoes: PropTypes.array.isRequired
};

export default LaudoTextualLesao;