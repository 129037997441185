import React from 'react';
import Produtividade from '../../componentes/inicio/produtividade';

const PaginaProdutividade = () => {
    return (
        <Produtividade />       
    )
}

export default PaginaProdutividade;
