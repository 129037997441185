import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { SttTabs } from '@stt-componentes/core';
import { PERFIL, PERMISSOES } from '../../common/Constants';
import { TIPO_ABA_EXAME, VINCULO_PERFIL } from '../../componentes/exame/constantes';
import { temPermissaoRede } from '../../secutity/acl';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import usuario from '../../signals/usuario';
import { resetFiltros } from '../../signals/exame';

import Aba from '../../componentes/exame/aba';

const Exame = () => {

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useSignals();

    const navigate = useNavigate();
    const location = useLocation();

    const resetAba = () => {
        navigate('/exames');
        resetFiltros();
    }

    useSignalEffect(() => {
        let dadosAbas = [];
        if (usuario.value.perfisRBAC) {

            const perfisInteresse = usuario.value.perfisRBAC.filter(p => [
                PERFIL.ADMINISTRADOR, 
                PERFIL.ADMINISTRADOR_ESTADUAL,
                PERFIL.MEDICO_LAUDADOR, 
                PERFIL.MEDICO_SOLICITANTE,
                PERFIL.TECNICO, 
                PERFIL.VISUALIZADOR,
                PERFIL.VISUALIZADOR_REDE
            ].includes(p.identificador));
            perfisInteresse.forEach(perfil => {
                // INSTITUIÇÃO
                if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
                    perfil.instituicoes.forEach(instituicao => {
                        dadosAbas.push({
                            id: instituicao.id,
                            tipo: TIPO_ABA_EXAME.INSTITUICAO,
                            titulo: instituicao.nome,
                            conteudo: Aba,
                            handleClick: resetAba                  
                        });
                    })
                }
    
                // REDE
                if (perfil.vinculo === VINCULO_PERFIL.REDE) {
                    perfil.redes.forEach(rede => {
                        const redeJaAdd = dadosAbas.some(d => (d.id === rede.id && d.tipo === TIPO_ABA_EXAME.REDE));
                        if (!redeJaAdd) {
                            dadosAbas.push({
                                id: rede.id,
                                tipo: TIPO_ABA_EXAME.REDE,
                                titulo: rede.descricao,
                                conteudo: Aba,
                                laudador: temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME_FABRICA) ? 1 : 0,
                                handleClick: resetAba
                            });
                        }
                    });
                }
        
                // GLOBAL
                if (perfil.vinculo === VINCULO_PERFIL.GLOBAL || 
                    perfil.identificador === PERFIL.ADMINISTRADOR_ESTADUAL || 
                    perfil.identificador === PERFIL.MEDICO_SOLICITANTE) {
                    dadosAbas.push({
                        id: perfil.identificador,
                        tipo: TIPO_ABA_EXAME.PERFIL,
                        titulo: `Perfil ${perfil.descricao}`,
                        conteudo: Aba,
                        handleClick: resetAba
                    });
                }
    
            });
            abas.value = dadosAbas;
        }

        if (location.state?.aba) {
            abaAtiva.value = ''+location.state.aba;
        }
    });

    return (
        <SttTabs abas={abas.value} abaAtiva={abaAtiva.value} />
    );

}

export default Exame;