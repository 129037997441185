import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao/index';
import { MODALIDADE } from '../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';
import carregando from '../../signals/carregando';
import { SttTranslateHook } from '@stt-componentes/core';

const Aba = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { ...other } = props;
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

    useSignals();

    const loading = (open) => {
        carregando.value = { open: open, text: strings.gerandoDocumento };
    }
    
    const imprimir = (idExame) => {
        loading(true);
        const idCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: { 
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao
            }
        };

        axios
            .get(`${EXAME_API_BASE_URL}/exame/${idCodificado}/imprimir`, opcoes)
            .then((response) => {
                loading(false);
                window.open(response.data);
            })
            .catch(err => {
                loading(false);
                console.log(err);
            });

    }

    const imprimirProtocolo = (exame) => {
        loading(true);
        const idExameCodificado = Buffer.from(exame.id_exame?.toString() || exame.id?.toString()).toString('base64');
        const params = { 
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios
            .get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                loading(false);
                window.open(response.data);
            })
            .catch(err => {
                loading(false);
                console.log(err);
            });
    }

    const imprimirTermo = (exame) => {
        loading(true);
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        const idBuffer = Buffer.from(exame.id_paciente.toString());
        const uf = exame.uf || exame.sigla_uf;
        let url = `${ESTOMATO_API_BASE_URL}/termo-autorizacao/${idBuffer.toString('base64')}/uf/${uf}`;
        axios
            .get(url, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                loading(false);
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                loading(false);
                console.log(err);
            });
    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
        </>
    );

}

export default Aba;