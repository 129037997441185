import { 
    LESAO_FUNDAMENTAL,
    COR,
    SUPERFICIE,
    PALPACAO,
    TEMPO_EVOLUCAO,
    TEMPO,
    UNIDADE_MEDIDA,
    TEMPO_EVOLUCAO_NAO_CONHECIDO,
    PACIENTE_ASSINTOMATICO,
    LOCALIZACAO_DOR,
    ESPONTANEIDADE_DOR,
    DURACAO_DOR,
    INTENSIDADE_DOR,
    TIPO_DOR,
    QUEIMACAO,
    SENSIBILIDADE,
    LESAO_SAI_RASPAGEM,
    TAMANHO,
    ALTURA,
    LARGURA,
    ESTRUTURA_AFETADA
} from './fieldNames';

export const initialValues = {
    [LESAO_FUNDAMENTAL]: '',
    [ESTRUTURA_AFETADA]: null,
    [TAMANHO]: {
        [ALTURA]: null,
        [LARGURA]: null,
        [UNIDADE_MEDIDA]: null
    },
    [COR]: '',
    [SUPERFICIE]: '',
    [PALPACAO]: '',
    [TEMPO_EVOLUCAO]: {
        [TEMPO]: '',
        [UNIDADE_MEDIDA]: null
    },
    [TEMPO_EVOLUCAO_NAO_CONHECIDO]: false,
    [PACIENTE_ASSINTOMATICO]: false,
    [LOCALIZACAO_DOR]: '',
    [ESPONTANEIDADE_DOR]: '',
    [DURACAO_DOR]: '',
    [INTENSIDADE_DOR]: '',
    [TIPO_DOR]: '',
    [QUEIMACAO]: '',
    [SENSIBILIDADE]: '',
    [LESAO_SAI_RASPAGEM]: ''
};