import {
    ID_EXAME,
    ID_INSTITUICAO,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    DESCRICAO_ESTUDO,
    ORIENTACOES,
    CONCLUSAO,
    CID_10,
    DECS,
    CLASSIFICACAO_RISCO,
    HIPOTESE_DIAGNOSTICA,
    NUMERO,
    COMPLEMENTO_HIPOTESE_DIAGNOSTICA
} from './fieldNames';

import {
    LESOES,
    ESTRUTURA_AFETADA,
    LESAO_FUNDAMENTAL,
    COMPLEMENTO_LESAO_FUNDAMENTAL,
    LOCALIZACAO,
    TAMANHO,
    ALTURA,
    LARGURA,
    UNIDADE_MEDIDA,
    COR,
    OUTRA_COR,
    SUPERFICIE,
    OBSERVACAO_SUPERFICIE,
    PALPACAO,
    TEMPO_EVOLUCAO,
    TEMPO,
    TEMPO_EVOLUCAO_NAO_CONHECIDO,
    TIPO_CRESCIMENTO,
    PACIENTE_ASSINTOMATICO,
    LOCALIZACAO_DOR,
    ESPONTANEIDADE_DOR,
    DURACAO_DOR,
    INTENSIDADE_DOR,
    TIPO_DOR,
    QUEIMACAO,
    SENSIBILIDADE,
    LESAO_SAI_RASPAGEM,
    LADO_AFETADO,
    ARCADA_AFETADA,
    REGIAO_AFETADA,
    OCORRENCIA_LESAO
} from '../solicitacao/lesoes/fieldNames';
import { VERSAO } from '../../common/Constants';
import { initialValues as lesoesInitialValues} from '../solicitacao/lesoes/initialValues';

export default (dados) => {
    const values = {
        [ID_EXAME]: dados.exame.id_exame,
        [ID_INSTITUICAO]: dados.exame.id_instituicao,
        [ID_LAUDO_EMISSAO]: dados.idLaudoEmissao,
        [DESCRICAO_ESTUDO]: 'Exame de Estomatologia',
        [TIMESTAMP_INICIO]: new Date().getTime(),
        [CID_10]: [],
        [DECS]: [],
        [LESOES]: []
    };

    if (dados.lesoes.length === 0) {
        values[LESOES].push({
            [NUMERO]: 1,
            [LADO_AFETADO]: null,
            [REGIAO_AFETADA]: null,
            [ARCADA_AFETADA]: null,
            [OCORRENCIA_LESAO]: null,
            ...lesoesInitialValues
        });
        return values;
    }

    dados.lesoes.forEach(lesao => {
        let lesaoValues = {
            [NUMERO]: lesao.numero,
            [ESTRUTURA_AFETADA]: lesao.estrutura_afetada,
            [LESAO_FUNDAMENTAL]: lesao.id_indicacao_estomato_cadastro_lesao_fundamental,
            [COMPLEMENTO_LESAO_FUNDAMENTAL]: lesao.complemento_lesao_fundamental,
            [LOCALIZACAO]: lesao.id_indicacao_estomato_cadastro_localizacao_lesao,
            [TAMANHO]: {
                [ALTURA]: lesao.altura_lesao_fundamental,
                [LARGURA]: lesao.largura_lesao_fundamental,
                [UNIDADE_MEDIDA]: lesao.unidade_medida_tamanho_lesao_fundamental
            },
            [COR]: lesao.id_indicacao_estomato_cadastro_cor,
            [OUTRA_COR]: lesao.complemento_cor,
            [SUPERFICIE]: lesao.id_indicacao_estomato_cadastro_superficie,
            [OBSERVACAO_SUPERFICIE]: lesao.complemento_superficie,
            [PALPACAO]: lesao.id_indicacao_estomato_cadastro_palpacao,
            [TEMPO_EVOLUCAO]: {
                [TEMPO]: lesao.tempo_evolucao || '',
                [UNIDADE_MEDIDA]: lesao.id_indicacao_estomato_cadastro_especificacao_tempo_evolucao ? { id: lesao.id_indicacao_estomato_cadastro_especificacao_tempo_evolucao, descricao: lesao.descricao_tempo_evolucao } : null
            },
            [TEMPO_EVOLUCAO_NAO_CONHECIDO]: lesao.tempo_evolucao_nao_conhecido,
            [TIPO_CRESCIMENTO]: lesao.id_indicacao_estomato_cadastro_tipo_crescimento,
            [PACIENTE_ASSINTOMATICO]: lesao.assintomatica,
            [LOCALIZACAO_DOR]: lesao.localizacao_dor,
            [ESPONTANEIDADE_DOR]: lesao.espontaneidade_dor,
            [DURACAO_DOR]: lesao.duracao_dor,
            [INTENSIDADE_DOR]: lesao.intensidade_dor,
            [TIPO_DOR]: lesao.tipo_dor,
            [QUEIMACAO]: lesao.queimacao,
            [SENSIBILIDADE]: lesao.alteracao_sensibilidade,
            [LESAO_SAI_RASPAGEM]: lesao.raspagem,
            [ORIENTACOES]: null,
            [CONCLUSAO]: null,
            [CLASSIFICACAO_RISCO]: '',
            [HIPOTESE_DIAGNOSTICA]: null,
            [COMPLEMENTO_HIPOTESE_DIAGNOSTICA]: null
        };
        if (dados.exame.versao === VERSAO.ANTIGA) {
            lesaoValues[ESTRUTURA_AFETADA] = lesao.estrutura_afetada?.split(',');
            lesaoValues[LADO_AFETADO] = lesao.lado_afetado;
            lesaoValues[REGIAO_AFETADA] = lesao.regiao_afetada?.split(',');
            lesaoValues[ARCADA_AFETADA] = lesao.arcada_afetada;
            lesaoValues[OCORRENCIA_LESAO] = lesao.ocorrencia_lesao;
        }
        values[LESOES].push(lesaoValues);
    });
    return values;
};