import React, { useContext } from 'react';
import { Formik } from 'formik';
import Formulario from './form-upload/index';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import { schema } from './form-upload/validationSchema';
import { initialValues } from './form-upload/initialValues';
import { useMoment } from '../../hooks';
import { SttTranslateHook } from '@stt-componentes/core';
import { ARQUIVO, DATA_AQUISICAO, LESAO_FUNDAMENTAL } from './form-upload/fieldNames';
import usuario from '../../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import carregando from '../../signals/carregando';
import alerta from '../../signals/alerta';
import { solicitacao } from '../../signals/envio-imagens';


const EnvioImagens = ({ enviar, resetFormulario, handleCancelarEnvio, callbackFinalizarEnvio }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const moment = useMoment();
    const validationSchema = schema(strings);
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;
    
    const initialFormValues = useSignal({});
    const examesEnviados = useSignal([]);
    const gerarProtocolo = useSignal(false);
    const gerarTermo = useSignal(false);
    const paciente = useSignal(null);
    const ufSolicitante = useSignal(null);

    useSignals();

    useSignalEffect(() => {
        initialFormValues.value = initialValues(solicitacao.value.dados);
    });

    useSignalEffect(() => {
        if (gerarProtocolo.value) {
            if (examesEnviados.value.length) {
                carregando.value = { ...carregando.value, text: strings.gerandoNumeroProtocolo };
                imprimirProtocolo(examesEnviados.value);
            }
        }
        if (gerarTermo.value) {
            if (paciente.value && ufSolicitante.value) {
                carregando.value = { ...carregando.value, text: strings.gerandoTCLE };
                imprimirTermo(paciente.value, ufSolicitante.value);
            }
        }
    });

    const cancelarEnvio = () => {
        if (handleCancelarEnvio) {
            handleCancelarEnvio();
        }
        resetFormulario();
    }

    /**
     * Reseta o formulário de envio de imagens e da solicitação
     */
    const finalizarEnvioImagens = () => {
        if (callbackFinalizarEnvio) {
            callbackFinalizarEnvio();
        }
        resetFormulario();
    }
    
    /**
     * Gera o protocolo do exame
     * 
     * @param {array of number} idExame 
     */
    const imprimirProtocolo = (exames) => {
        gerarProtocolo.value = false;
        const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
        const idExameCodificado = Buffer.from(exames.join(',')).toString('base64');
        const params = { 
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios
            .get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroGerarProtocoloEnvioExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => alerta.value = { ...alerta.value, open: false }
                        }
                    ],
                    onClose: () => alerta.value = { ...alerta.value, open: false }
                };
            })
            .finally(() => gerarTermo.value = true);
    }

    /**
     * Gera o termo de autorização
     * 
     * @param {number} paciente
     */
    const imprimirTermo = (pac, uf) => {
        gerarTermo.value = false
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        axios
            .get(`${ESTOMATO_API_BASE_URL}/termo-autorizacao/${btoa(pac)}/uf/${uf}`, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
                carregando.value = { ...carregando.value, open: false };
                finalizarEnvioImagens();
            })
            .catch(err => {
                carregando.value = { ...carregando.value, open: false };
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroGerarTermo,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = { ...alerta.value, open: false };
                                finalizarEnvioImagens();
                            }

                        }
                    ],
                    onClose: () => alerta.value = { ...alerta.value, open: false }
                };
            });
    }

    return (
        solicitacao.value.dados && 
        <Formik
            enableReinitialize
            initialValues={initialFormValues.value}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                carregando.value = { open: true, text: strings.mensagemEnviandoImagens };

                let dados = { ...data };
                const formData = new FormData();

                formData.append('id_funcionario', usuario.value.idFuncionario);
                formData.append('id_solicitacao', solicitacao.value.dados.id);
                formData.append('id_equipamento', dados['equipamento'].id);
                if (dados.observacao?.trim()) {
                    formData.append('observacao', dados.observacao.trim());
                }
                
                const lesoes = Object.keys(dados).filter(k => k.includes('lesao_'));
                lesoes.forEach(lesao => {
                    dados[lesao].forEach((arquivo, idx) => {
                        formData.append(`${lesao}_${idx}_${LESAO_FUNDAMENTAL}`, arquivo[LESAO_FUNDAMENTAL]);
                        formData.append(`${lesao}_${idx}_${ARQUIVO}`, arquivo[ARQUIVO]);
                        formData.append(`${lesao}_${idx}_${DATA_AQUISICAO}`, moment(arquivo[DATA_AQUISICAO]).format('YYYY-MM-DD'));
                    });
                });
                axios
                    .post(`${ESTOMATO_API_BASE_URL}/exame`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data'} })
                    .then((response) => {

                        // Armazena o identificador do exame gerado
                        examesEnviados.value = [...examesEnviados.value, response.data.data.exame];
                        paciente.value = response.data.data.paciente;
                        ufSolicitante.value = response.data.data.uf;

                        resetForm();
                        gerarProtocolo.value = true;
                    })
                    .catch(err => {
                        console.error(err);
                        carregando.value = { ...carregando.value, open: false };

                        const { response } = err;
                        let msg = strings.mensagemErroGeral;
                        let titulo = '';
                        let msgAlerta = '';

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const dadosResp = response.data;
                                let arrMensagem = [];
                                dadosResp.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                msg = arrMensagem.join('\n');
                                titulo = dadosResp.message;
                                msgAlerta = msg;
                            } else {
                                titulo = strings.erro;
                                msgAlerta = msg;
                            }
                        } else {
                            titulo = strings.erro;
                            msgAlerta = msg;
                        }
                        alerta.value = {
                            title: titulo,
                            message: msgAlerta,
                            type: 'error',
                            open: true,
                            options: [
                                {
                                    title: strings.ok,
                                    onClick: () => alerta.value = { ...alerta.value, open: false }
                                }
                            ],
                            onClose: () => alerta.value = { ...alerta.value, open: false }
                        };
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (
                        <>
                            <form noValidate onSubmit={handleSubmit}>
                                {
                                    <Formulario
                                        open={enviar}
                                        solicitacao={solicitacao.value.dados}
                                        enviarFn={handleSubmit}
                                        confirmarFecharModal={() => {
                                            alerta.value = {
                                                title: strings.cancelar,
                                                message: strings.mensagemCancelarEnvioImagens,
                                                type: 'confirmation',
                                                open: true,
                                                options: [
                                                    {
                                                        title: strings.sim,
                                                        onClick: () => {
                                                            alerta.value = { ...alerta.value, open: false };
                                                            cancelarEnvio();
                                                        }
                                                    },
                                                    {
                                                        title: strings.nao,
                                                        onClick: () => alerta.value = { ...alerta.value, open: false }
                                                    }
                                                ],
                                                onClose: () => alerta.value = { ...alerta.value, open: false }
                                            };
                                        }}
                                    />
                                }
                            </form>
                        </>
                    )
                }
            }
        </Formik>
    );
}

export default EnvioImagens;
